<template>
  <svg-icon :data="sData" v-bind="$attrs" class="pkg-feather" />
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconDatabase extends Vue {
  sData: string = require("@icon/feather/database.svg");
}
</script>
